@import url('https://fonts.googleapis.com/css2?family=Princess+Sofia&display=swap');

$font-family-princess: 'Princess Sofia', cursive;

$color-background: #f8f3e5;

.recent-project {
  min-height: 100vh;
  background: $color-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 3rem;

  .title {
    margin-top: 5rem;
  }

  .project {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    img {
      width: 100%;
      max-width: 400px;
    }

    a, a:visited {
      color: #2f3533;
      text-decoration: none;

      &:hover {
        color: lighten(#2f3533, 15%);
      }
    }

    .dautenmannetje {
      font-family: $font-family-princess;
      font-size: .9em;
      text-align: center;
      margin-top: .5rem;
    }
  }
}
