@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway&display=swap');

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;

$color-background: #7cad92;
$color-title: #2f3533;
$color-subtitle: #2f3533;
$color-first: #f8f3e5;
//$color-accent: #D53557;
$color-accent: #E2C561;

.header {
  background-color: $color-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding: 0 .5rem;
}

.title {
  margin-bottom: 1rem;
  color: $color-title;
  text-transform: uppercase;
  font-family: $font-family-montserrat;
  letter-spacing: .1rem;
  text-align: center;
}

.subtitle {
  color: $color-subtitle;
  font-size: 1.2rem;
  text-transform: lowercase;
  font-family: $font-family-raleway;
  text-align: center;
}

.first {
  color: $color-first;
}

.separator {
  color: $color-accent;
}

