@import url(https://fonts.googleapis.com/css2?family=Antonio:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Princess+Sofia&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.header{background-color:#7cad92;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:3rem;padding:0 .5rem}.title{margin-bottom:1rem;color:#2f3533;text-transform:uppercase;font-family:"Montserrat",sans-serif;letter-spacing:.1rem;text-align:center}.subtitle{color:#2f3533;font-size:1.2rem;text-transform:lowercase;font-family:"Raleway",sans-serif;text-align:center}.first{color:#f8f3e5}.separator{color:#E2C561}

.recent-project{min-height:100vh;background:#f8f3e5;display:flex;flex-direction:column;align-items:center;justify-content:flex-start;font-size:3rem}.recent-project .title{margin-top:5rem}.recent-project .project{flex:1 1;display:flex;flex-direction:column;justify-content:center;padding:2rem}.recent-project .project img{width:100%;max-width:400px}.recent-project .project a,.recent-project .project a:visited{color:#2f3533;text-decoration:none}.recent-project .project a:hover,.recent-project .project a:visited:hover{color:#535e5a}.recent-project .project .dautenmannetje{font-family:"Princess Sofia",cursive;font-size:.9em;text-align:center;margin-top:.5rem}

